import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthUserIsUserAuthenticated } from '../features/auth/store/auth-user-store'
import HealthcareProfessionalAuth from '../features/healthcare-professional/components/healthcare-professional-auth'
import { Path } from '../shared/enums'
import { getPath } from '../shared/utils/demo'

export default function HealthcareProfessionalSignUp() {
  const navigate = useNavigate()
  const isUserAuthenticated = useAuthUserIsUserAuthenticated()

  useEffect(() => {
    if (isUserAuthenticated) {
      navigate(getPath(Path.HealthcareProfessionalUserApplications))
    }
  }, [isUserAuthenticated, navigate])

  return (
    <div className="sm:w-8/12 flex flex-col sm:mx-auto mx-6 my-4">
      <HealthcareProfessionalAuth />
    </div>
  )
}
