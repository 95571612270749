import ErrorExpiredShortUrl from '../features/error/components/error-expired-short-url'
import ErrorFallback from '../features/error/components/error-fallback'
import { Page } from '../interfaces/page'
import { Path } from '../shared/enums'
import ClinicalTrialDetail from './clinical-trial-detail'
import ClinicalTrials from './clinical-trials'
import HealthCareProfessionalApplication from './healthcare-professional-application'
import HealthcareProfessionalSignUp from './healthcare-professional-sign-up'
import PatientApplicationComplete from './patient-application-complete'
import HealthcareProfessionalUserApplications from './healthcare-professional-user-applications'

const pages: Page[] = [
  {
    component: <ClinicalTrials />,
    path: Path.ClinicalTrials,
  },
  {
    component: <ClinicalTrialDetail />,
    path: Path.ClinicalTrialDetail,
  },
  {
    component: <HealthCareProfessionalApplication />,
    path: Path.HealthCareProfessionalApplication,
  },
  {
    component: <PatientApplicationComplete />,
    path: Path.PatientApplicationComplete,
  },
  {
    component: <HealthcareProfessionalSignUp />,
    path: Path.HealthCareProfessionalSignUp,
  },
  {
    component: <HealthcareProfessionalUserApplications />,
    path: Path.HealthcareProfessionalUserApplications,
  },
  {
    component: <ErrorExpiredShortUrl />,
    path: Path.ExpiredShortUrl,
  },
  {
    component: <ErrorFallback />,
    path: Path.Error,
  },
]

export default pages
