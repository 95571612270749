/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  ActionIcon, Button, Image, Text, Tooltip, UnstyledButton,
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TbUser } from 'react-icons/tb'
import { HiOutlineLogout } from 'react-icons/hi'
import { images } from '../../../shared/utils/public'
import { Path } from '../../../shared/enums'
import { getPath } from '../../../shared/utils/demo'
import { useAuthUserActions, useAuthUserIsUserAuthenticated, useAuthUserLoggedUser } from '../../auth/store/auth-user-store'
import { AvatarInitials } from '../../../shared/components/user/avatar-initials'
import stringHelper from '../../../shared/helpers/string-helper'

export default function Header() {
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])

  const { logout } = useAuthUserActions()
  const isUserAuthenticated = useAuthUserIsUserAuthenticated()
  const user = useAuthUserLoggedUser()

  const handleLogout = () => {
    logout()
    navigate(getPath(Path.HealthCareProfessionalSignUp))
  }

  const { text: name, isTextLimited: isNameLimited } = stringHelper.limitTextDetailed(user?.name ?? '', 50)

  return (
    <div className="h-full flex justify-between items-center px-5">
      <Image
        className="cursor-pointer"
        h={20}
        src={images.resconnLogo.src}
        alt={images.resconnLogo.alt}
        w="auto"
        onClick={() => navigate(getPath(Path.ClinicalTrials))}
      />
      {isUserAuthenticated ? (
        <div className="flex items-center gap-3">
          <div
            className="hidden sm:flex items-center gap-3 cursor-pointer"
            onClick={() => navigate(getPath(Path.HealthcareProfessionalUserApplications))}
          >
            {isNameLimited ? (
              <Tooltip label={user.name}>
                <Text size="sm" fw={500} className="text-gray-700">{name}</Text>
              </Tooltip>
            ) : (
              <Text size="sm" fw={500} className="text-gray-700">{name}</Text>
            )}
            <AvatarInitials text={user.name} size="2rem" />
          </div>
          <div className="sm:hidden">
            <AvatarInitials text={user.name} size="2rem" />
          </div>
          <ActionIcon onClick={handleLogout} variant="subtle" color="gray.4">
            <HiOutlineLogout color="#868e96" size={18} />
          </ActionIcon>
        </div>
      ) : (
        <>
          <Button
            className="hidden sm:block sm:text-sm text-md sm:h-9 h-10"
            bg="violet.6"
            onClick={() => navigate(getPath(Path.HealthCareProfessionalSignUp))}
          >
            {t('common:header.login-as-doctor')}
          </Button>
          <div className="sm:hidden">
            <UnstyledButton
              onClick={() => navigate(getPath(Path.HealthCareProfessionalSignUp))}
            >
              <TbUser size={24} color="#6741D9" />
            </UnstyledButton>
          </div>
        </>
      )}
    </div>
  )
}
