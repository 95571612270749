import { SubmitApplicationEligibilityTestResponseAttributes } from '../../../interfaces/eligibility-test/submit-application-eligibility-test-response-attributes'
import { CreateHealthcareProfessionalApplicationAttributes } from '../../../interfaces/healthcare-professional-application'
import { api } from '../../../libs'

const createHealthcareProfessionalApplication = async (createHealthcareProfessionalApplicationAttributes: CreateHealthcareProfessionalApplicationAttributes) => {
  const createHealthcareProfessionalApplicationResponse = await api.healthcareProfessionalApplications
    .createHealthcareProfessionalApplication(createHealthcareProfessionalApplicationAttributes)
  return createHealthcareProfessionalApplicationResponse
}

const submitApplicationEligibilityTestResponse = async (submitApplicationEligibilityTestResponseAttributes: SubmitApplicationEligibilityTestResponseAttributes) => {
  const createHealthcareProfessionalApplicationResponse = await api.healthcareProfessionalApplications
    .submitApplicationEligibilityTest(submitApplicationEligibilityTestResponseAttributes)
  return createHealthcareProfessionalApplicationResponse
}

const processHealthcareProfessionalApplicationAuthorization = async (id: string) => {
  const getHealthcareProfessionalApplicationResponse = await api.healthcareProfessionalApplications
    .processHealthcareProfessionalApplicationAuthorization({ id })
  return getHealthcareProfessionalApplicationResponse
}

const healthcareProfessionalApplicationService = {
  createHealthcareProfessionalApplication,
  submitApplicationEligibilityTestResponse,
  processHealthcareProfessionalApplicationAuthorization,
}

export default healthcareProfessionalApplicationService
