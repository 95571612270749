import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import healthcareProfessionalApplicationService from './services/healthcare-professional-application-service'
import { MutationParams } from '../../interfaces/queries'
import { queryClient } from '../../config'
import i18n from '../../config/i18n'
import { SubmitApplicationEligibilityTestResponseAttributes } from '../../interfaces/eligibility-test/submit-application-eligibility-test-response-attributes'
import { ExtendedHealthcareProfessionalApplication } from '../../models'
import { CreateHealthcareProfessionalApplicationAttributes } from '../../interfaces/healthcare-professional-application'
import EligibilityTestResponseResult from '../../interfaces/eligibility-test/eligibility-test-result'

const healthcareProfessionalApplicationKeys = {
  all: [{ scope: 'healthcareProfessionalApplications' }] as const,
  lists: () => [{ ...healthcareProfessionalApplicationKeys.all[0], entity: 'list' }] as const,
  single: (id: string) => [{ ...healthcareProfessionalApplicationKeys.all[0], entity: 'single', id }] as const,
}

export const useCreateHealthcareProfessionalApplicationDetails = (params?: MutationParams<ExtendedHealthcareProfessionalApplication>) => useMutation({
  mutationFn: (createHealthcareProfessionalApplicationAttributes: CreateHealthcareProfessionalApplicationAttributes) => healthcareProfessionalApplicationService
    .createHealthcareProfessionalApplication(createHealthcareProfessionalApplicationAttributes),
  onSuccess: (newHealthcareProfessionalApplication) => {
    queryClient.setQueryData<ExtendedHealthcareProfessionalApplication[]>(healthcareProfessionalApplicationKeys.lists(), (healthcareProfessionalApplications) => {
      if (!healthcareProfessionalApplications) return [newHealthcareProfessionalApplication]

      return [newHealthcareProfessionalApplication, ...healthcareProfessionalApplications]
    })

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(newHealthcareProfessionalApplication)
  },
  onError: params?.onError,
})

export const useSubmitApplicationEligibilityTestResponse = (params?: MutationParams<EligibilityTestResponseResult>) => useMutation({
  mutationFn: (submitApplicationEligibilityTestResponseAttributes: SubmitApplicationEligibilityTestResponseAttributes) => healthcareProfessionalApplicationService
    .submitApplicationEligibilityTestResponse(submitApplicationEligibilityTestResponseAttributes),
  onSuccess: (eligibilityTestResponse) => {
    params?.onSuccess(eligibilityTestResponse)
  },
})

export const useProcessHealthcareProfessionalApplicationAuthorization = (id?: string, params?: MutationParams<ExtendedHealthcareProfessionalApplication>) => useMutation({
  mutationFn: () => healthcareProfessionalApplicationService.processHealthcareProfessionalApplicationAuthorization(id!),
  onSuccess: (authorizedHealthcareProfessionalApplication) => {
    queryClient.setQueryData<ExtendedHealthcareProfessionalApplication[]>(healthcareProfessionalApplicationKeys.lists(), (healthcareProfessionalApplications) => {
      if (!healthcareProfessionalApplications) return [authorizedHealthcareProfessionalApplication]

      return [authorizedHealthcareProfessionalApplication, ...healthcareProfessionalApplications]
    })

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(authorizedHealthcareProfessionalApplication)
  },
})
