import api from '../../../libs/api'

const getHealthcareProfessionalUserApplications = async () => {
  const response = await api.applications.getHealthcareProfessionalUserApplications()
  return response
}

const applicationsService = {
  getHealthcareProfessionalUserApplications,
}

export default applicationsService
