import clinicalTrial from './controllers/clinical-trials'
import auth from './controllers/auth'
import healtcareProfessionals from './controllers/healtcare-professionals'
import healthcareProfessionalApplications from './controllers/healthcare-professional-applications'
import researchCenters from './controllers/research-centers'
import applications from './controllers/applications'

const api = {
  clinicalTrial,
  auth,
  healtcareProfessionals,
  healthcareProfessionalApplications,
  researchCenters,
  applications,
}

export default api
