import { Skeleton } from '@mantine/core'

export default function HealthcareProfessionalUserApplicationsSkeleton() {
  return (
    <>
      <Skeleton height={130} />
      <Skeleton height={130} />
    </>
  )
}
