import { kyInstance } from '../config/ky'
import {
  CreateHealthcareProfessionalApplicationRequest,
  ProcessHealthcareProfessionalApplicationAuthorizationRequest,
  SubmitApplicationEligibilityTestRequest,
} from '../requests/healthcare-professional-applications'
import {
  CreateHealthcareProfessionalApplicationResponse,
  ProcessHealthcareProfessionalApplicationAuthorizationResponse,
  SubmitApplicationEligibilityTestResponse,
} from '../responses/healthcare-professional-applications'

const HEALTHCARE_PROFESSIONAL_APPLICATIONS_BASE_URL = 'healthcare-professional-applications'

const createHealthcareProfessionalApplication = async (request: CreateHealthcareProfessionalApplicationRequest) => kyInstance
  .post(HEALTHCARE_PROFESSIONAL_APPLICATIONS_BASE_URL, {
    json: request,
  }).json<CreateHealthcareProfessionalApplicationResponse>()

const submitApplicationEligibilityTest = async (request: SubmitApplicationEligibilityTestRequest) => kyInstance
  .post(`${HEALTHCARE_PROFESSIONAL_APPLICATIONS_BASE_URL}/${request.healthcareProfessionalApplicationId}/eligibility-test-response`, {
    json: request,
  }).json<SubmitApplicationEligibilityTestResponse>()

const processHealthcareProfessionalApplicationAuthorization = async (request: ProcessHealthcareProfessionalApplicationAuthorizationRequest) => kyInstance
  .post(`${HEALTHCARE_PROFESSIONAL_APPLICATIONS_BASE_URL}/${request.id}/authorize`, {
    json: request,
  }).json<ProcessHealthcareProfessionalApplicationAuthorizationResponse>()

export default {
  createHealthcareProfessionalApplication,
  submitApplicationEligibilityTest,
  processHealthcareProfessionalApplicationAuthorization,
}
