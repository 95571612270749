import { Badge } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { ApplicationStatus } from '../enum/application-status'
import { ApplicationResearchCenterStatus } from '../enum/application-research-center-status'

interface Props {
  status: ApplicationStatus;
  researchCenterStatus: ApplicationResearchCenterStatus;
}

export function ApplicationResearchCenterStatusBadge({ status, researchCenterStatus }: Props) {
  const { t } = useTranslation(['application'])

  switch (researchCenterStatus) {
    case ApplicationResearchCenterStatus.Randomized:
      return <Badge color="violet.8" variant="light" radius="sm">{t('application:user-applications.status.randomized')}</Badge>

    case ApplicationResearchCenterStatus.SentToResearchCenter || ApplicationResearchCenterStatus.OpenedByResearchCenter:
      return <Badge color="violet.8" variant="light" radius="sm">{t('application:user-applications.status.sent-to-center')}</Badge>

    case ApplicationResearchCenterStatus.Pending:
      switch (status) {
        case ApplicationStatus.Eligible:
          return <Badge color="violet.8" variant="light" radius="sm">{t('application:user-applications.status.eligible')}</Badge>
        case ApplicationStatus.NotEligible:
          return <Badge color="violet.8" variant="light" radius="sm">{t('application:user-applications.status.not-eligible')}</Badge>
        default:
          return <Badge color="violet.8" variant="light" radius="sm">{t('application:user-applications.status.not-eligible')}</Badge>
      }

    default:
      return <Badge color="violet.8" variant="light" radius="sm">{t('application:user-applications.status.randomized')}</Badge>
  }
}
