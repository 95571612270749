import { ExtendedApplication } from '../../../models'
import HealthcareProfessionalUserApplicationCard from './healthcare-professional-user-application-card'

interface Props {
  applications: ExtendedApplication[] | undefined
}

export default function HealthcareProfessionalUserApplicationsList({ applications }: Props) {
  return (
    <div className="flex flex-col gap-3">
      {applications?.map((application) => (
        <HealthcareProfessionalUserApplicationCard key={application.id} application={application} />
      ))}
    </div>
  )
}
