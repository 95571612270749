import { useQuery } from 'react-query'
import QueryParams from '../../interfaces/queries/query-params'
import applicationsService from './services/applications-service'
import { ExtendedApplication } from '../../models'
import GetHealthcareProfessionalUserApplicationsResponse from '../../libs/api/responses/applications/get-healthcare-professional-user-applications'

const applicationKeys = {
  all: [{ scope: 'applications' }] as const,
  lists: () => [{ ...applicationKeys.all[0], entity: 'list' }] as const,
  single: (id: string) => [{ ...applicationKeys.all[0], entity: 'single', id }] as const,
}

export const useHealthcareProfessionalUserApplicationsQuery = <ModelSchema = ExtendedApplication[]>(
  params?: QueryParams<ExtendedApplication[], ModelSchema>,
) => {
  const query = useQuery<GetHealthcareProfessionalUserApplicationsResponse>({
    queryKey: applicationKeys.lists(),
    queryFn: applicationsService.getHealthcareProfessionalUserApplications,
  })

  return {
    ...query,
    data: params?.select ? params.select(query.data?.applications ?? []) : query.data?.applications as ModelSchema,
    authorizedApplicationsAmount: query.data?.authorizedApplicationsAmount,
    totalApplicationsAmount: query.data?.totalApplicationsAmount,
  }
}
