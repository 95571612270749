import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PageWrapper } from './features/layout'
import NotFoundPage from './shared/components/not-found-page/not-found-page'
import pages from './pages'

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {pages.map((page) => (
          <Route
            key={page.path}
            path={page.path}
            element={<PageWrapper>{page.component}</PageWrapper>}
          />
        ))}
        {pages.map((page) => (
          <Route
            key={`/demo${page.path}`}
            path={`/demo${page.path}`}
            element={<PageWrapper>{page.component}</PageWrapper>}
          />
        ))}
        <Route
          path="*"
          element={(<NotFoundPage />)}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
