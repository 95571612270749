import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { ExtendedApplication } from '../../../models'
import { ApplicationResearchCenterStatusBadge } from './application-research-center-status-badge'

interface Props {
  application: ExtendedApplication
}

export default function HealthcareProfessionalUserApplicationCard({ application }: Props) {
  const { t } = useTranslation(['application'])

  return (
    <div className="flex flex-col gap-2 border-gray-100 border border-width-[1px] rounded-sm px-6 py-4 w-full">
      <ApplicationResearchCenterStatusBadge status={application.status} researchCenterStatus={application.researchCenterStatus} />
      <div className="flex flex-col gap-1">
        <Text size="md" c="gray.7" fw={500}>
          {application.patientName}
        </Text>
        <Text c="gray.6" fw={500}>
          {application.clinicalTrial.title}
        </Text>
        <Text c="gray.6" fw={500}>
          {application.clinicalTrial.interventionName}
          {' '}
          •
          {' '}
          {application.clinicalTrial.pathologyName}
        </Text>
        <Text c="gray.6" fw={500}>
          {t('application:user-applications.card.last-update')}
          {' '}
          {application.updatedAt}
        </Text>
      </div>
    </div>
  )
}
