import {
  Anchor, Button, Input, PinInput, Text, Title,
} from '@mantine/core'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PhoneNumberInputAttributes, SMSCodeConfirmationAttributes, VerifyAttributes } from '../../../interfaces/auth'
import { formatPhoneNumber } from '../../../shared/utils/formatters'

interface Props {
  phone: string
  onSMSCodeConfirmationInput: (verifyAttributes: VerifyAttributes) => void
  onResendVerificationCode: (phoneAttributes: PhoneNumberInputAttributes) => void
}

export default function SMSCodeConfirmationForm({ phone, onSMSCodeConfirmationInput, onResendVerificationCode }: Props) {
  const { t } = useTranslation()

  const {
    handleSubmit, control, formState: { errors, isValid },
  } = useForm<SMSCodeConfirmationAttributes>({ mode: 'onTouched' })

  return (
    <div className="flex flex-col">
      <Title order={1} fw={600} c="gray.9">{t('healthcareProfessional:auth:confirm-phone')}</Title>
      <Text className="mt-2" c="gray.6">{t('healthcareProfessional:auth:phone-code-info', { phone })}</Text>
      <form onSubmit={handleSubmit(
        (SMSAttributes) => onSMSCodeConfirmationInput({ ...SMSAttributes, phone: formatPhoneNumber(phone) }),
      )}
      >
        <div className="flex flex-col gap-3 w-4/5 mx-auto mt-8">
          <Controller
            name="code"
            control={control}
            rules={{
              required: { value: true, message: t('common:form-error:required-field') },
              validate: {
                validcode: (code) => code.length === 6,
              },
            }}
            render={({ field }) => (
              <Input.Wrapper error={errors.code && errors.code?.message}>
                <PinInput
                  classNames={{
                    input: 'text-violet-900 border-violet-900 ring-transparent focus:ring-violet-200 ring-2',
                  }}
                  size="lg"
                  length={6}
                  placeholder=""
                  type="number"
                  c="blue"
                  {...field}
                />
              </Input.Wrapper>
            )}
          />
          <Text className="text-start" c="gray.6">
            {t('healthcareProfessional:auth:did-not-receive-code')}
            <Anchor onClick={() => onResendVerificationCode({ phone })}>{t('healthcareProfessional:auth:resend-phone-code')}</Anchor>
          </Text>
        </div>
        <Button
          fullWidth
          color="violet"
          disabled={!isValid}
          className="mt-8 text-md h-10"
          type="submit"
        >
          {t('common:confirm')}
        </Button>
      </form>
    </div>
  )
}
