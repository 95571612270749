enum Path {
  ClinicalTrials = '/clinical-trials',
  ClinicalTrialDetail = '/clinical-trials/:id',
  HealthCareProfessionalApplication = '/healthcare-professional-applications/:clinicalTrialId',
  PatientApplicationComplete = '/patient-application-complete',
  HealthCareProfessionalSignUp = '/sign-up',
  HealthcareProfessionalUserApplications = '/applications',
  ExpiredShortUrl = '/error/expired-short-url',
  Error = '/error',
}

export default Path
