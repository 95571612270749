import { kyInstance } from '../config/ky'
import GetHealthcareProfessionalUserApplicationsResponse from '../responses/applications/get-healthcare-professional-user-applications'

const APPLICATIONS_BASE_URL = 'applications'

const getHealthcareProfessionalUserApplications = async () => kyInstance.get(APPLICATIONS_BASE_URL).json<GetHealthcareProfessionalUserApplicationsResponse>()

export default {
  getHealthcareProfessionalUserApplications,
}
