import {
  AppShell, Image, Text, rem,
} from '@mantine/core'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHeadroom } from '@mantine/hooks'
import { useSearchParams } from 'react-router-dom'
import { images } from '../../../shared/utils/public'
import { useResearchCenterStoreActions } from '../../research-center/store/research-center-store'
import Header from './header'

interface Props {
  children: ReactElement;
}

export default function PageWrapper({ children }: Props) {
  const { t } = useTranslation(['common'])
  const pinned = useHeadroom({ fixedAt: 60 })
  const researchCenterStoreActions = useResearchCenterStoreActions()

  const [searchParams] = useSearchParams()
  const researchCenterNameQueryParam = searchParams.get('research-center')
  if (researchCenterNameQueryParam) researchCenterStoreActions.setResearchCenterName(researchCenterNameQueryParam)

  return (
    <AppShell
      header={{ height: 80, collapsed: !pinned, offset: false }}
      footer={{ height: 80 }}
    >
      <AppShell.Header className="w-100vw">
        <Header />
      </AppShell.Header>
      <AppShell.Main
        pt={`calc(${rem(60)} + var(--mantine-spacing-md))`}
        pb={0}
        className="md:min-h-[calc(100vh-80px)] min-h-[calc(100vh-70px)]"
      >
        {children}
      </AppShell.Main>
      <AppShell.Footer className="w-full relative left-0 bottom-0">
        <div className="flex flex-col justify-center items-center sm:flex-row sm:justify-between gap-3 h-full px-5 bg-violet-800">
          <Image h={20} src={images.whiteResconnLogo.src} alt={images.whiteResconnLogo.alt} w="auto" />
          <Text c="white" className="text-sm sm:text-md">
            {t('common:footer:resconn-rights')}
          </Text>
        </div>
      </AppShell.Footer>
    </AppShell>
  )
}
