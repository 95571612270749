import { Text } from '@mantine/core'

interface Props {
  description: string
}

export default function InfoCard({ description }: Props) {
  return (
    <div className="flex gap-3 items-center bg-purple-100 rounded-lg p-2 w-full">
      <div className="flex items-center gap-3">
        <Text size="md">💡</Text>
        <Text c="gray.8" fw={400}>
          {description}
        </Text>
      </div>
    </div>
  )
}
