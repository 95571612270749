import { Button, Text } from '@mantine/core'
import { TbFaceMask, TbThumbUp } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuthUserLoggedUser } from '../features/auth/store/auth-user-store'
import SmallCard from '../shared/components/card/small-card'
import InfoCard from '../shared/components/card/info-card'
import HealthcareProfessionalUserApplicationsList from '../features/applications/components/healthcare-professional-user-applications-list'
import { useHealthcareProfessionalUserApplicationsQuery } from '../features/applications/queries'
import HealthcareProfessionalUserApplicationsSkeleton from '../features/applications/components/healthcare-professional-user-applications-skeleton'
import { getPath } from '../shared/utils/demo'
import { Path } from '../shared/enums'

export default function HealthcareProfessionalUserApplications() {
  const { t } = useTranslation(['application'])
  const navigate = useNavigate()
  const user = useAuthUserLoggedUser()

  const {
    data: applications, authorizedApplicationsAmount, totalApplicationsAmount, isLoading,
  } = useHealthcareProfessionalUserApplicationsQuery()

  return (
    <div className="sm:w-8/12 flex flex-col sm:mx-auto mx-6 my-4">
      <div className="flex flex-col">
        <div className="flex flex-col gap-">
          <Text size="lg" fw={500}>
            {t('application:user-applications.greeting', { name: user.name })}
          </Text>
          <Text c="gray.8">{t('application:user-applications.hub-description')}</Text>
        </div>
        <Button className="w-full mt-5 h-10" onClick={() => navigate(getPath(Path.ClinicalTrials))}>
          {t('application:user-applications.enroll-patient')}
        </Button>
        <div className="flex align-center justify-center w-full gap-4 mt-5 xl:px-30 lg:px-25 md:px-15 sm:px-8 px-2">
          <SmallCard
            Icon={TbFaceMask}
            title={t('application:user-applications.subscribed-patients')}
            description={totalApplicationsAmount?.toString() ?? '0'}
          />
          <SmallCard
            Icon={TbThumbUp}
            title={t('application:user-applications.authorized-patients')}
            description={authorizedApplicationsAmount?.toString() ?? '0'}
          />
        </div>
        <div className="flex flex-col gap-3 mt-6">
          <Text size="lg" fw={500}>
            {t('application:user-applications.your-patients')}
          </Text>
          <InfoCard description={t('application:user-applications.privacy-notice')} />
          {isLoading ? (
            <HealthcareProfessionalUserApplicationsSkeleton />
          ) : (
            <HealthcareProfessionalUserApplicationsList applications={applications} />
          )}
        </div>
      </div>
    </div>
  )
}
