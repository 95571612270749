import { IconType } from 'react-icons'
import { Text } from '@mantine/core'

interface Props {
  Icon: IconType
  title: string
  description: string
}

export default function SmallCard({
  Icon, title, description,
}: Props) {
  return (
    <div className="flex gap-3 items-center justify-center w-full border-gray-300 border border-width-[1px] rounded-lg p-2">
      {Icon && (
      <Icon size={22} color="#7950F2" />
      )}
      <div className="flex flex-col gap-1">
        <Text c="gray.8" fw={400}>
          {title}
        </Text>
        <Text size="md" c="gray.8" fw={500}>
          {description}
        </Text>
      </div>
    </div>
  )
}
