import {
  Button, Card, Text, Title,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import { IoLogoWhatsapp } from 'react-icons/io5'
import { HiOutlineLockClosed } from 'react-icons/hi'
import { TbReportMedical } from 'react-icons/tb'
import { Path } from '../../../shared/enums'
import { getPath } from '../../../shared/utils/demo'

export default function EligibilityTestResultPending() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="flex flex-col xl:max-w-220 lg:max-w-200 md:mt-5 mt-3">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <IoIosCheckmarkCircleOutline size={40} color="#7048E8" />
          <Title fw={600} c="gray.9">{t('application:result:eligbility-test-complete')}</Title>
        </div>
        <Text size="md" c="gray.6">
          {t('application:result:next-steps')}
        </Text>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <div className="flex flex-col gap-2">
              <HiOutlineLockClosed size={24} color="#7048E8" />
              <Text size="sm" c="gray.9" fw={600}>
                {t('application:result:steps:encrypted-title')}
              </Text>
              <Text size="sm" c="gray.8">
                {t('application:result:steps:encrypted-description')}
              </Text>
            </div>
          </Card>
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <div className="flex flex-col gap-2">
              <IoLogoWhatsapp size={24} color="#7048E8" />
              <Text size="sm" c="gray.9" fw={600}>
                {t('application:result:steps:whatssap-title')}
              </Text>
              <Text size="sm" c="gray.8">
                {t('application:result:steps:whatssap-description')}
              </Text>
            </div>
          </Card>
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <div className="flex flex-col gap-2">
              <TbReportMedical size={24} color="#7048E8" />
              <Text size="sm" c="gray.9" fw={600}>
                {t('application:result:steps:share-title')}
              </Text>
              <Text size="sm" c="gray.8">
                {t('application:result:steps:share-description')}
              </Text>
            </div>
          </Card>
        </div>
      </div>
      <Button
        className="mt-8 text-md h-10"
        onClick={() => navigate(getPath(Path.HealthcareProfessionalUserApplications))}
      >
        {t('common:complete')}
      </Button>
    </div>
  )
}
